.d-flex{
    display: flex;
    
}

.build-right{
    margin-top: 20px;
}

.flex-column{
    flex-direction: column;
}

.d-none{
    display: none;
}
.mt-2{
    margin-top: 5px;
}
.align-items-center{
    align-items: center;
}

.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}

.w-100{
    width: 100%;
}

 .p-2{
padding: 5px;
}

.m-2 {
    margin: 5px;
    }

    .form-check-input{
        margin-right: 5px !important;
    }

    .mt-4{
        margin-top: 10px;
    }

    .mt-5 {
        margin-top: 10px;
    }