.sections {
  padding: 10px 20px;
  width: 33%;
  text-align: center;
  margin: 0;
  color: white;
  font-size: 1.7rem;
  min-height: 35px;
  background-color: #52ba9b;
}
.section-bg {
  background-color: #52ba9b;
  color: white !important;
  outline: none;
}
.section-bg:hover {
  background-color: #38806a;
  color: white !important;
  outline: none;
}
.section-active {
  background-color: #38806a;
  color: white !important;
  outline: none;
}
.w-90 {
  width: 90%;
}
.w-10 {
  width: 10%;
}
.h-100 {
  height: 100%;
}
.edit-section {
  background-color: #52ba9b;
  color: #fff;
  min-height: 35px;
  height: 100%;
  width: 10%;
}
.edit-section:hover {
  background-color: #38806a;
}

#react-doc-viewer {
  height: 90vh;
  width: 700px;
}
#header-bar {
  display: none;
}

.build-section button {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
}
.build-right .dropdown .show > div {
  font-size: 14px;
  color: #696868;
}
.respond-section button {
  height: 35px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
}
.submission {
  font-size: 1.8rem;
}
.submission th {
  color: #696969;
}
.submission td {
  color: #696868;
}

.submission-section button,
.view-section button {
  min-width: 200px;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
}

.submission-section button:hover,
.view-section button:hover {
  background-color: #38806a;
}

.submission-section i:hover {
  cursor: pointer;
}
.delete-field {
  padding: 10px;
  margin-right: 30px;
}

.delete-field:hover {
  background-color: #52ba9b;
  color: white;
  cursor: pointer;
  /* padding: 10px; */
}
