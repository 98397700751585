
.dropdown .dropdown-menu {
    left:8px !important;
    transition: all 600ms ease;
    -moz-transition: all 200ms ease;
    -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -o-transition: all 0.001ms ease;
    -ms-transition: all 2000ms ease;
  }
  
  .dropdown-content {
     
     width:150px;
    padding:15px 15px 15px 10px ;
    display: none;
    border: none;
    box-shadow: 0px 0px 6px 0px #f1f0f0;
  }

  .superoptions{
    border-top:2px solid rgb(197, 197, 197);
    border-left:2px solid rgb(197, 197, 197);
    border-right:2px solid rgb(197, 197, 197);
    text-align:center;
    padding:7px 0px;
    
    margin-left:15px;
    font-size:15px;
    font-family:montserrat,'sans-serif'
}
  .superoptions:hover{ 
      cursor:pointer;
  }
  .dropdown:hover .dropdown-menu {
    margin-left:-30px;
    width:50px;
    display: block;
    margin-top:-10px;
  }
  .dropdown-item {
    padding: 13px 2px !important;
    list-style:none;
    font-family:raleway,'sans-serif';
    color:rgb(97, 97, 97);
  }
  
  .dropdown-item:hover {
      text-decoration:none;
    color: rgb(2, 56, 87) !important;
   font-weight:400;
    list-style:none;
    font-family:raleway,'sans-serif';
  }

.subText{
    font-size:15px;
    margin-left:0px;
    
}.sidebar {
    background-color: #ffffff;
    height: calc(100vh - 90px);
    margin-top: 0px;
    padding: 10px 0px;
    width: 100%;
    box-shadow: 0px 1px 9px 0px #f7f5f5;
}
#sidebar1{
    display:block;
}
.sidebar-elementhead {
    font-size: 15px !important;
    font-weight: 700;
    padding: 0px 5px 5px 13px;
    font-family: Montserrat,'sans-serif';
    color: #696868 !important;
    text-align: left;
}


.sidebar-head{
    font-size:15px !important;
    font-weight:700;
    padding:10px;
    
    font-family:Montserrat,'sans-serif';
    color:#23527c  !important;
    text-align:left;
    padding-left:15px;
    

}

.sidebar-element {
    margin: 4px 0px 6px 0px;
    padding: 8px 1px;
    font-size: 1.4rem;
    text-align: left;
    border-bottom: 1px solid #fbf6f6;
    font-family: Montserrat,'sans-serif';
    color: #696868;
    font-weight: 600;
}
.sidebar-element:hover {
  
    color: #0fb475 !important;
    font-weight: 600;
    cursor:pointer;
}
.sidebar-subelement {
    padding: 2px 10px 2px 9px;
    font-size: 1.3rem;
    text-align: left;
    font-family: Montserrat,'sans-serif';
    color: rgb(99 97 97);
    font-weight: 500;
    display: none;
}
.sidebar-subelement:hover{
    
   cursor:pointer;
   color:#0fb475 !important;

}
.download-button > button {
    margin-top: -12px;
    margin-left: -8px;
    font-size: 13px;
    font-family: 'montserrat';
    width: '190px';
    font-weight: 600;
    margin-top: 0px !important;
    border-radius: 6px;
    box-shadow: 2px 1px 9px 0px #e1e1e1;
    padding: 3px 10px 4px 10px !important;
    color: rgb(255 255 255) !important;
    background-color: rgb(14 186 154) !important;
    border: none;
}
.workflow-input{
    width: 540px;
    border-radius: 9px;
    border: 1px solid #f3f2f2;
    padding: 5px !important; 
    
}

.workflow-input1{
    background-color:#ffffff !important;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    border: 1px solid rgb(243 240 240) !important;
    width: 99% !important;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}

.sub-tabs{
    padding:10px;
    background-color:rgb(253, 253, 253);
    
    border-radius:4px;
}

.sub-tabs:hover{
    padding:10px;
    background-color:rgb(245, 245, 245);
    
    border-radius:4px;
}

.sidebar-sectionName{
    opacity:0.8;
    font-size:18px;
    padding:8px;
    text-align:left;
    font-weight:700;
    color:grey;
    font-family:Montserrat,'sans-serif';
}

.oneTab{
    margin:5px 0px;
}
.fa{
    
    padding:0px 6px;
}
.right{
    text-align:right;
}

.navbar-brand{
    padding:10px 10px 0px 10px;

    height:57px;
}
.subtext{
    color:#307AB2;
    font-size:14px;
    font-weight:500;
    font-family:montserrat,'sans-serif';
    margin-top:-5px;
    margin-left:8px;
}
.navbar {
    background-color: #ffffff;
    height: 90px;
    /* box-shadow: 0px -5px 10px #fdfdfd; */
    z-index: 9999;
    width: auto;
    margin-bottom: 0px !important;
    border-bottom:1px solid rgb(241, 241, 241);
}
.navbar-nav>li>a {
    padding-top: 26px;
    padding-bottom: 22px;
    
}

.tab-organisation {
    border-radius: 10px;
    margin: 15px 90px 10px 0px;
    padding: 5px;
    width: 286px;
    height: 200px;
    box-shadow: 9px 4px 12px #ebeaea;
    background-color: rgb(254, 255, 255);
    /* box-shadow: 3px 2px 8px 0px #dddddd; */
}

.mainScreen {
    background-color: #ffffff;
    height: 66vh;
}
.mainScreenView {
    overflow-x: hidden;
    background-color: #fbfbfb;
    height: 100vh;
}
   

.mainDiv{
    
  
    overflow-y:none;
    overflow-x:scroll;
    background-color: #fbfbfb;
}

.tabImage{
    width:140px;
}
.tab-row{
    margin-top:-20px;
}
.tab-text{
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    font-size:14px;
}

.tab-text-main {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    font-size: 15px;
    color: rgb(36, 36, 36);
}
/*form - css*/
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: rgb(119, 119, 119);
    background-color: #ffffff;
    background-image: none;
    border: 1px solid rgb(243 240 240) !important;
    border-radius: 4px;
    font-family: montserrat,'sans-serif';
    box-shadow: none !important;
}

.form-text{
    
    font-size:13px;
    font-family: Montserrat,'sans-serif';
    font-weight:500;
    
}


.table>tbody>tr>td, .table>tfoot>tr>td {
    padding: 4px 4px 4px 11px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid rgb(236, 236, 236);
    width:200px;
}
.form-textHead{
    font-size:13px;
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    text-align:left !important;
   color:rgb(44, 43, 43);
   text-transform:uppercase;
   border-bottom:1px solid grey;
   
}

.badge{
    padding:10px 20px;
    background-color: green;
    border-radius:20px;
    box-shadow:0px 4px 6px 0px #8e8b8b;
}






.cell{
    padding:10px 10px;
    border-top:1px solid rgb(235, 235, 235);
    
    width:500px !important; 
    background-color:rgb(255, 255, 255);
    text-align: center;
}
.cell-head{
    padding:10px 10px;
   
    border-bottom:1px solid grey;
    width:auto; 
    background-color:rgb(255, 255, 255);

}
.busi-row{
    padding:10px;
    border-top:1px solid rgb(230, 230, 230);
}
.CardBody{
    border:1px solid rgb(236, 236, 236);
    
  }

    .red{
        background-color: red;
        
    }
    .link{
        padding-top:20px;
        color:blue;
        }
    
    .link:hover{
    color:rgb(5, 5, 126);
    }

    a { color: inherit; } 

    a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    a:active {
        color: #ffffff !important;
        text-decoration: none;
    }
    .header-divi {
        height: 204px;
        padding: 10px 13px;
        background-color: #ffffff;
        overflow: auto;
    }
    .header-divi-mail {
        height: 100px;
        padding: 10px 13px;
        background-color: #ffffff;
        
    }

    .add-button {
        background-color:   rgb(255 255 255)  !important;
        border: none;
        margin-left: 8px;
        border-radius: 3px;
        margin-bottom: 4px;
        font-size: 13px;
        padding: 5px 0px 5px 20px;
        width: 110px;
    }
    .sticky {
        position: sticky;
        top: 0px;
        z-index: 100;
        width: 100%;
    }
    
    .account-head {
        color: rgb(53, 53, 53);
        font-family: raleway,'sans-serif';
        font-weight: 400;
        margin-top: 15px;
        font-size: 17px !important;
        font-family: montserrat,'sans-serif';
        margin-bottom: 10px;
        /* margin-left: 30px; */
        background-color: #E5EEEF;
        padding: 10px 14px;
        font-weight: 600;
    }
    .account-head1 {
        color: rgb(53, 53, 53);
        font-family: raleway,'sans-serif';
        font-weight: 400;
        margin-top: 2px;
        font-size: 20px !important;
        font-family: montserrat,'sans-serif';
        margin-bottom: -3px;
        /* margin-left: 30px; */
        background-color: #52ba9b3f;
        padding: 10px 46px;
        font-weight: 600;
    }
    .download-button-wrapper {
        margin-top: -12px;
        margin-left: -8px;
        font-size: 13px;
        font-family: 'montserrat';
        width: '190px';
        font-weight: 600;
        margin-top: 0px !important;
        border-radius: 6px;
        box-shadow: 2px 1px 9px 0px #e1e1e1;
        padding: 3px 10px 4px 10px !important;
        color: rgb(255 255 255) !important;
        background-color: rgb(14 186 154) !important;
        border: none;
    }
    .is-fullscreen{
        position: fixed;
        top: 0;
        overflow: auto;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        background: #ffffff;
    }
    .full-width{
        width: 100%;
    }
    .css-yk16xz-control {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: hsl(0,0%,100%);
        border-color: hsl(0deg 6% 95%) !important;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        box-sizing: border-box;
    }


    .css-yk16xz-control:hover{
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: hsl(0,0%,100%);
        border-color: hsl(0deg 1% 93%) !important;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        margin-top: 8px;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        box-sizing: border-box;
    }
    .saveButton{
        background-color: rgb(24, 192, 128) !important;
       border:none;
       padding:0px 0px 0px 10px;
       width:55px;
    }
    
.header-divi1{
    height:120px;
  
padding:10px 30px;
background-color: white;}

.head-name{font-weight:400;
    font-size:23px;
    text-align:left;
}

.head-mail{
    font-weight:400;
    font-size:14px;
    text-align:left;
    font-family:raleway,'sans-serif';
}
.head-mail-important{
    font-weight:600;
    font-size:16px;
    text-align:left;
    font-family:raleway,'sans-serif';
}

.head-button{
    margin-top:20px;
    font-weight:400;
    font-size:14px;
    text-align:left;
}

.dedicated-page {
    margin-right: 17px;
    overflow-x: hidden;
    height: 78vh;
    border-radius: 5px;
    margin-left: -3px;
    padding-bottom: 50px;
    background-color: white;
    box-shadow: 0px 1px 11px 0px #f5f5f5 !important;
}
.dedicated-page1 {
    margin-right: 36px;
    overflow-x: hidden;
    height: 620px;
    margin-left: -44px;
    background-color: white;
    border: 1px solid #f3f0f0;
}
.Name-tag{
    color:#3f3f3f;
    font-size:14px;
    font-weight:600;
}
.dedicated1-page{
    margin-right:36px;
    overflow-x:hidden;
    height:620px;
    background-color: white;
    margin-left:-30px;
    border:1px solid rgb(233, 233, 233);
}
#section{
    
    overflow-y: scroll;
    height:700px;
    border:1px solid rgb(235, 235, 235);
    margin-right:20px;
}
.content-div{
    padding:2px 60px;
    
}

.control-label {
    font-size: 15px;
    font-weight: 500;
    font-family: montserrat,'sans-serif';
    margin-top: 6px;
    color: #696868;
}


.content1-div{
    padding:30px 60px 20px 60px;
}
.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

.Div{
    
    margin-right:40px;
    margin-left:0px;
}
.options8{
   
    text-align:center;
    padding:8px 10px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}


.active2{
    color:rgb(33, 108, 194) !important;
     font-weight:600 !important;
 }
 .active1{
    border-top: 2px solid rgb(73, 133, 189) !important;
    border-left: 2px solid rgb(73, 133, 189) !important;
    border-right: 2px solid rgb(73, 133, 189) !important;
     font-weight:600px;
 }
 
.add-header {
    font-weight: 600;
    margin-bottom: 50px;
    margin-top: 40px;
    font-size: 18px;
    color: rgb(32, 32, 32);
    font-family: montserrat,'sans-serif';
    background-color: #e5eeef;
    padding: 10px 49px;
}

.options8{
   
  cursor:pointer
}

.list-head{
    color:white;
    font-family:raleway,'sans-serif';
    font-size:18px;
    font-weight:600;
    text-align:left;
    margin-bottom:40px;
    border-radius:4px;
    border-bottom: 1px solid #eeeeee;
    padding:10px 10px;
    margin-left:-15px;
    background-color:#1E5B81;
}
.section {
    margin-left: 12px;
    width: 98%;
}

.detail-fields {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    font-size: 13px !important;
    margin-top: 0px;
    color: rgb(56, 56, 56);
    background-color: #e8e9e930;
    /* border: 1px solid; */
    border: 1px solid #e5efef;
    padding: 8px;
    text-align: center;
}

.head-button1{
    margin-top:20px;
    border-radius:6px;
    padding:2px;
    color:rgb(59, 59, 59);
    font-weight:600;
   
    border:2px solid rgb(216, 253, 194);
}

.payStatus{
    background-color: transparent;
    border: none;
    font-size: 13px;
    margin-top: 6px;
 }
 .form-group {
    padding: 20px;
}
 
 .processStatus{
    background-color: transparent;
    border: none;
    font-size: 13px;
    margin-top: 6px;
 }
 
 .head-button2 {
    margin-top: 6px;
    border-radius: 6px;
    font-weight: 600;
    padding: 2px;
    color: #515050;
    border:2px solid rgb(194, 225, 253);
    
}

.sidebar-link {
    color: #696868 !important;
    text-decoration: none !important;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 14px;
    margin-left: 4px;
    margin-right: 10px;
}

.sidebar-link:hover{
    color:#04a78b !important;
    
}
.tablebusi{
    width:auto;
}
.dedicated-div{
    
}

.tab-image{
    color:rgb(253, 113, 113) !important;
    height:50px;
    margin-top:20px;
    font-size:50px;
}
.tab-image1{
    color:rgb(106, 183, 255) !important;
    height:40px;
    margin-top:20px;
    font-size:50px;
}

.tab-image2{
    color:rgb(103, 255, 141) !important;
    height:40px;
    margin-top:20px;
    font-size:50px;
}

.tab-image3{
    color:rgb(243, 208, 54) !important;
    height:40px;
    margin-top:20px;
    font-size:50px;
}


li{
    text-decoration:none !important;
}

.tab {
    border-radius: 8px;
    margin: 10px 0px;
    padding: 5px;
    width: 182px;
    height: 143px;
    background-color: rgb(255 255 255);
    box-shadow: 7px 7px 16px 0px #efefef;
}
.tab:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
}
.tab-main {
    color: #363535;
    border-radius: 21px;
    margin: 30px 0px;
    padding: 5px;
    width: 230px;
    height: 180px;
    background-color: #ffffff;
    box-shadow: 7px 7px 20px 0px #dfdfdfbf;
}
.tab-row{
    padding:10px;
    border-top:5px solid rgb(248, 248, 248);
    border-bottom:5px solid rgb(248, 248, 248);
    margin-top:10px;
}
.tab-row1{
    padding:10px;
  
    margin-top:10px;
}
.tab-row-head{
font-size:20px;
color:rgb(68, 68, 68);
font-family:montserrat,'sans-serif';
}

.Account-Card{
    margin:10px 120px;
    padding:20px;
    border:2px solid rgb(228, 228, 228);
    border-radius:10px;
    height:500px;
}

.account-info{
    color:rgb(22, 31, 36);
    font-family: raleway,'sans-serif';
    
    font-weight:500;
    font-size:17px;
    padding:10px 0px;
 }
 .account-col1{
  
    padding-top:60px;
  
 }
 .account-col2{
   
    margin-top:30px;
  
 }

 .info-column{
     
 }

 input[type=text], input[type=password], input[type=email] {
    background-color: #ffffff !important;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    border: 1px solid rgb(243 240 240) !important;
    width: 74% !important;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}

 .nav-item{
     
     margin-right:-20px;
    font-size:15px;
    font-family:raleway,'sans-serif';
    color:rgb(26, 26, 26) !important;
}
.nav-item1{
    margin-right:10px;
   font-size:14px;
   font-family:raleway,'sans-serif';
   color:rgb(26, 26, 26) !important;
}
.nav-item:hover{
    color:black;
    
}

.nav-item:active{
    color:black;
   
}

.tableDiv {
    height: 70vh;
    overflow: auto;
    display: block;
}

.myTable{
    height: 74vh;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{

    border: none !important;
}

.ReactTable .rt-thead.-header{
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.15) !important;
}

.ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    background-color: white;
    border: none !important;
    box-shadow: 0px 1px 9px 0px #f7f5f5;

}


.fieldbox{
    box-shadow:none;
}

.rt-td {
    border-top: 1px solid rgb(248, 248, 248) !important;
  
    border-left: 1px solid rgb(248, 248, 248) !important;
    border-right: 1px solid rgb(248, 248, 248) !important;
    height:140px;
   
}
 .ReactTable .rt-td {
    flex: 1 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    height: 50px;
    transition-property: width,min-width,padding,opacity;
}

.ReactTable .rt-th{
    flex: 1 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    height: 22px;
    transition-property: width,min-width,padding,opacity;
}
.ReactTable .rt-th, .ReactTable .rt-td {
    flex: 1 0;
    white-space: nowrap;
    font-family: montserrat !important;
    font-size: 13px !important;
    font-weight: 500;
    text-overflow: ellipsis;
    padding: 7px 5px;
    margin-top: 2px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
}

.content1-div > input[type=text], input[type=password] , input[type=email]{
        background-color: #ffffff !important;
        color: #444444 !important;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        margin: 5px;
        border:1px solid rgb(209, 209, 209);
        width: 98%;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }

    textarea {
        background-color: #ffffff !important;
        color: #444444 !important;
        padding: 7px 10px;
        height: 122px;
        border: 1px solid #dadada;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        margin: 4px 12px 0px 5px;
        /* margin: 1px 0px 0px 0px; */
       
        width: 85%;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }
    input, button, select, textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
.button1{
    margin: 49px 30px;
    background-color: #3bc28a!important;
    border: none;
    font-family: montserrat,'sans-serif';
    color: rgb(255 255 255) !important;
    padding: 10px 21px !important;
    text-align: center;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 1px 11px 0px #dbdbdbd6;

}

.dashboardHead{
    font-size:18px;
    font-weight:500;
    border-bottom:1px solid #e6e6e6;
    padding-bottom:15px;
    margin-left:5px;
    font-family:montserrat,'sans-serif'
}
.card{
    background-color: white;
}
.dashboardCard {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #efefef;
    padding: 20px;
    margin-left: 0px;
    width: 79vw;
}
/* FOR DASHBOARD CARDS */
.card-counter {
    box-shadow: -3px 7px 9px 0px #f7f7f7;
    margin: 20px 18px 5px -13px;
    padding: 7px 20px !important;
    background-color: #fff;
    height: 154px;
    /* width: 263px; */
    border-radius: 10px;
    transition: .3s linear all;
}

.fa-ticket{
    color:white;
}
.card-counter:hover{
    box-shadow: -3px 7px 9px 0px #f7f7f7;
    transition: .3s linear all;
  }

  .card-counter.primary{
    background-color: rgb(255, 255, 255);
    color: #FFF;
  }

  .card-counter.primary1{
    background-color: rgb(8, 196, 243);
    color: #FFF;
  }
  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  

  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  

  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  

  .card-counter i {
    font-size: 7em;
    opacity: 0.5;
  }

  .card-counter .count-numbers {
    position: absolute;
    right: 55px;
    top: 35px;
    font-size: 46px;
    display: block;
    color: #52ba9b;
    font-weight: 600;
}

.card-counter .count-name {
    position: absolute;
    right: 55px;
    top: 100px;
    color: #767575;
    text-transform: capitalize;
    opacity: 1;
    display: block;
    font-size: 18px;
    font-weight: 600;
    font-family: montserrat, sans-serif;
}

.row-resource-head{
    color: #ffffff;
    font-size: 17px;
    padding:2px 0px 2px 7px !important;
    background-color: #52BA9B;
    
    
    }
    .resource-head {
        color: #ffffff;
        font-size: 15px;
        text-align:left;
        font-weight: 600;
        margin-left: 12px;
        margin-top: 12px !important;
    }
    
    .content-row{
        background-color:  #ffffff;
        padding:0px 0px 30px 19px;
     }
  .fa-plus{
    color:#52BA9B
}

.icon-carddd {
    color: #52BA9B;
}
  /* END FOR DASHBOARD CARDS */

  /*TIMELINE*/

  .timeline {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
    z-index: 0;
}
.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #919191;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 0;
}

.update {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.update:before {
    content: ' ';
    background: #22c0e8;
    display: inline-block;
    position: absolute;
    left: 34px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.update > li {
    margin: 20px 0;
    padding-left: 20px;
    z-index: 0;
}
.update > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 0;
}

.proposals{
    list-style-type:none !important;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.attachment {
    margin: 2px 0px 0px 0px !important;
    padding-top: 4px 10px !important;
    background-color: rgb(255, 255, 255) !important;
    border: 2px solid #52ba9b7a !important;
    border-radius: 4px !important;
    font-size: 10px;
    color: rgb(0, 0, 0);
}
#myFileInput {
    display:none;
}


.mail-head{
font-size:14px;
font-weight:500;

font-family:'montserrat', sans-serif;
}

.mail-subhead{
    font-size:14px;
    font-weight:400;
font-family:'montserrat', sans-serif;
}

.options-row {
    height: 60px;
    padding: 8px 40px;
    background-color: rgb(255, 255, 255);
     
}



.send-button{
    background-color: #1D5A81;
    width:200px;
    border:none;
    font-size:18px;
    margin-top:50px;
}

.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ql-toolbar.ql-snow {
    border: 1px solid #f3f3f3 !important;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
}
.ql-container.ql-snow {
    border: 1px solid #ececec !important;
}
.fieldbox1{
    width:900px;
}

.montserrat{
    font-family:montserrat,'sans-serif'
}

.timeline-head{
    font-size:17px;
    font-weight:600;
}

.timeline-subhead{
    font-size:12px;
    font-weight:400;
}

.timeline-card{
    
    padding:5px 20px;
    border-radius: 5px;
    width:800px;
    
    margin-left:30px;
}

.update-card{
    
    padding:5px 20px 30px 40px;
   
    
    width:100%;
}

.mail-card{
    border-left:1px solid  rgb(231, 231, 231);
    border-right:1px solid  rgb(233, 233, 233);
    border-bottom:1px solid  rgb(245, 245, 245);
    padding:10px 20px 10px 10px;
   
    
    width:100%;
}

.timeline-card > a:hover{
    color:black !important;
}

.timeline-elements:hover{
    color:black;
}

.mail-grid-button{
    background-color: #1D5A81;
    width:60px;
}

.notification-number {
    background-color: #ea3f33;
    border-radius: 50px;
    height: 18px;
    font-family: arial,sans-serif;
    margin-left: -8px;
    width: 18px;
    font-weight: 600;
    position: absolute;
    font-size: 11px;
    margin-top: -11px;
    color: #fff;
    padding: 2px 0px 0px 3px;
    margin-bottom: 0px;
    border: 2px solid rgb(240, 125, 125);
}

.notify-button{
    background-color: red;
    border-radius:50px;
   height:25px;
   margin-left:-15px;
   width:25px;
   font-size:13px;
   padding:0px;
    margin-bottom:30px;
    border:2px solid rgb(240, 125, 125);
}

.notify{
    padding-left:50px;
}

.bell-icon{
    height:25px;
}

.options{
    text-align:center;
    padding:7px 0px;
    color:rgb(255, 255, 255);
    font-size:14px;
    font-family:montserrat,'sans-serif'
}

.document-card{
    margin:40px 30px;
    padding:20px;
    background-color:rgb(255, 255, 255);
    font-weight:700px;
    height:120px;
    font-size:17px;
    border-radius:10px;
    border:1px solid rgb(223, 223, 223);
}

.options{
    cursor:pointer;
}

.header{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 40px;
    font-size:24px;
    
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:-5;
}

.header2{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 20px;
    font-size:24px;
    
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:-5;
}


.doc-section{
    background-color: #ffffff;
    border:1px solid rgb(226, 226, 226);
    height:auto;
    overflow:scroll;
}

.table-head{
    font-size:14px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
   width:225px;
   padding:10px;
   word-break:break-word !important;
   background-color: white;
   text-align:center;
    
}

.table-item {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 5px;
    width:225px;
    word-break:break-word !important;
    text-align: center;
    background-color: #e8e9e930;
    font-family: montserrat,'sans-serif';
}

.editButton{
    background-color: rgb(255, 208, 0) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
   color:black;
}
.header1{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 40px;
    font-size:24px;
    font-weight:700;
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:10;
}
.deleteButton{
    background-color: #ed0e0e !important;
    border:none;
    padding:0px 0px 0px 10px;
    width:55px;
    color:white !important;
}

.TrashButtonColumn{
    margin-left: 10px;
   border:1px solid rgb(80, 80, 80);
   padding:5px 10px 5px 3px !important;
   width:35px;
   background-color: white !important;
   color:black;
}

.url{
    background-color: rgb(255, 102, 55) !important;
   border:none;
   border-radius:0px;
   padding:0px 0px 0px 10px;
   width:55px;
   font-weight:600;
}
.add-button {
    background-color: rgb(255 255 255) !important;
    border: none;
    margin-left: 8px;
    border-radius: 3px;
    margin-bottom: 4px;
    font-size: 13px;
    padding: 5px 0px 5px 20px;
    width: 110px;
}
.row{
    text-align:left !important
}
.saveButton{
    background-color: rgb(24, 192, 128) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
}

.table-head1{
    font-size:14px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
 
   padding:10px;
   background-color: white;
   text-align:center;
    width:50px;
}

.table-item1 {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 24px;
    border: 1px solid rgb(198, 206, 207);
    text-align: center;
   
    font-family: montserrat,'sans-serif';
}
.doc-head-row{
    padding:10px 0px;
    border-bottom:1px solid rgb(230, 230, 230);
}

ul{
    padding-inline-start: 0px;
}

.date-text{
font-size:15px;
font-weight:400;
font-family:Montserrat,'sans-serif';
}

.from-text{
    font-size:17px;
    font-weight:400;
    font-family:Montserrat,'sans-serif';
}
.message-text{
        font-size:16px;
        font-weight:400;
        font-family:Montserrat,'sans-serif';
        margin:10px 0px 0px 0px;
        }
.subject-text{
    margin:-30px 0px 0px -20px;
            font-size:24px;
            font-weight:600;
            font-family:Montserrat,'sans-serif';
            }

.RED-mail{
    background-color: rgb(255, 193, 193);
}


.GREEN-mail{
    background-color: rgb(215, 255, 205);
}

.PURPLE-mail{
    background-color: rgb(138, 52, 250);
}

.YELLOW-mail{
    background-color:rgb(255, 251, 193);
}



.BLUE-mail{
    background-color: rgb(213, 235, 253);
    
}


.message-box{
    padding: 10px 30px !important;
    
    display: flex;
    width:430px;
    z-index:10;

}

a:hover{
color:inherit !important;
}

.panel-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 50px;
    font-family:Montserrat,'sans-serif';
}

.back-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 0px;
    font-family:Montserrat,'sans-serif';
}

.butn{
    cursor:pointer;
}

.modal{
    z-index:10;
}
.cross-row{
    padding:4px;
    background-color: rgb(233, 233, 233)
}

.upload-box {
    font-size: 12px;
    margin-top: 2px;
    margin-top: 1px;
    border-radius: 3px;
    padding: 13px;
    border: 1px solid rgb(243 240 240) !important;
    padding: 7px;
}
.chat-button {
    background-color: #fafafa;
    border-radius: 50px;
    height: 40px;
    border: none;
    width: 170px;
    color: #3f3f3f;
    font-weight: 600;
    font-size: 17px;
    margin-left: 70px;
    margin-top: 60px;
    box-shadow: 0px 0px 1px 1px #dedede;
}

.button-type{
    border:1px solid rgb(216, 214, 214);
    color:rgb(32, 32, 32);
    padding:7px 8px 0px 8px;
    border-radius:5px;
}

.masterMail-page{
    
    height:590px;
    overflow-y:scroll;
}

a:hover{
    color:inherit !important;
}
a:active{
    color:inherit !important;
}


.tooltip {
    
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
 .tooltiptext {
     width: 120px;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(32, 32, 32);
    font-size:13px;
    margin-left:-40px;
    margin-top:2px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    display:none;
    /* Position the tooltip */
    position: absolute;
    z-index: 5;
  }
  
  .nav-item:hover  .tooltiptext{
      display:block;
  }

  .loading{
    position: fixed;
    width: 76vw;
    height: 100vh;
    padding-top: 20%;
    z-index: 9;
    text-align: center;
  }

  .timeline-head{
    width: 100%;
    word-break: break-all;
  }

  .activeOptionAss{
    text-align:center;
   
   
  
    font-size:16px;
    font-family:montserrat,'sans-serif';
    font-weight:500;
    background-color: #ebf8fa00 ;
      
        color: #ffffff!important;
   
}

.sidebar-secondary{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 93px;
    width: 83%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }
  .sidebar-secondary-access{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 46px;
    width: 88%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }
  
  .sidebar-secondary-webzy{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 36px;
    width: 88%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }
  
  .sidebar-back-arrow:before{
    content: "\f060" !important;
  }
  
  .oneTab{
    
    left: -300px;
  }

 .sidebar-elementmodulename{

  margin-left: 10px;
  font-weight: 900;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: 10px;
  color: #23b37f;
 }